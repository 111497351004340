import Index from '@/Domains/Auth/Views/Index.vue';
const Login = () => import(/* webpackChunkName: "login" */ '@/Domains/Auth/Views/Login.vue');
const UserAgreements = () => import(/* webpackChunkName: "user-agreements" */ '@/Domains/Auth/Views/UserAgreements.vue');
const UserCompanies = () => import(/* webpackChunkName: "user-companies" */ '@/Domains/Auth/Views/UserCompanies.vue');
const UserPasswordRecovery = () => import(/* webpackChunkName: "user-password-recovery" */ '@/Domains/Auth/Views/UserPasswordRecovery.vue');
const UserMailCheck = () => import(/* webpackChunkName: "user-mail-check" */ '@/Domains/Auth/Views/UserMailCheck.vue');
const UserCreatePassword = () => import(/* webpackChunkName: "user-create-password" */ '@/Domains/Auth/Views/UserCreatePassword.vue');
const UserMailUnchecked = () => import(/* webpackChunkName: "user-mail-unchecked" */ '@/Domains/Auth/Views/UserMailUnchecked.vue');
const UserMailInvalid = () => import(/* webpackChunkName: "user-mail-invalid" */ '@/Domains/Auth/Views/UserMailInvalid.vue');

export default [
    {
        path: '/',
        component: Index,
        children: [
            {
                path: 'login',
                component: Login,
            },
            {
                path: 'usuario/termos/:companyId/:userId',
                component: UserAgreements,
            },
            {
                path: 'usuario/empresas',
                component: UserCompanies,
            },
            {
                path: 'usuario/valida-email/:token',
                component: UserMailCheck,
            },
            {
                path: 'usuario/confirmacao-pendente/:companyId/:userId',
                component: UserMailUnchecked,
            },
            {
                path: 'usuario/email-invalido/:companyId/:userId',
                component: UserMailInvalid,
            },
            {
                path: 'usuario/recuperar-senha',
                component: UserPasswordRecovery,
            },
            {
                path: 'usuario/altera-senha',
                component: UserCreatePassword,
            },
        ],
    },
];
